import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

export default function SisterDominique(props) {
  const {
    img: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "SisterDominique.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return <Img {...props} fluid={fluid} />
}
