import React from "react"
import { Link } from "gatsby"

import PageLayout from "../components/PageLayout"
import SisterDominique from "../images/SisterDominique"

export default function History() {
  return (
    <PageLayout>
      <h1 className="sr-only">The History of Aid For Chad</h1>
      <p className="mb-4">
        Cast your mind back to the Autumn of 1984, when we were all horrified to
        see the heart-breaking coverage on TV of the famine in Ethiopia. It
        inspired Bob Geldof to form Band Aid, followed the next summer by Live
        Aid. It also inspired a small group of women, here in Oakham, to raise
        money by holding a coffee morning and a ‘bring and buy’ sale. We all had
        small children, and the thought of having to watch our children die
        because we were unable to feed them was unimaginable. It spurred us on
        to raise more money. Little did we know that we were about to embark on
        a mission which would still be going strong decades later. We were on
        the verge of starting Aid For Chad – a mission that would become as much
        a part of our lives as our families.
      </p>
      <p className="mb-8">
        By chance, we heard of a nun doing incredible work in famine-stricken
        southern Chad, a region of Africa barely mentioned in the TV reports of
        the time. And even to this day, Chad’s struggle remains neglected by the
        World. The nun we heard of was Sister Dominique, who began sending us
        chilling first-hand accounts of the terrible conditions around Bénoye.
        She spoke so eloquently and with such emotion that she touched our
        hearts, and we vowed that we would help her. We discovered a way by
        which we could send money to arrive safely, so we started to raise funds
        in earnest.
      </p>
      <h2 className="mb-4 text-lg font-bold">
        Sister Dominique Spreads The Word of Chad’s Poverty
      </h2>
      <SisterDominique
        className="sm:float-left mb-5 sm:mr-5 sm:mb-0"
        style={{ width: 150 }}
      />
      <p className="mb-4">
        In mid-1985, Sister Dominique fell desperately ill with malnutrition and
        cerebral malaria, and was flown back to England. After 14 years serving
        the people of Chad, the terrible conditions had taken their toll:
        weighing only 5 stone, she was close to death, but miraculously she
        recovered her strength and over the next couple of years was able to
        visit the Aid for Chad charity on a number of occasions.
      </p>
      <p className="mb-8">
        Sister Dominique travelled around the British Isles spreading the word
        of Chad. She was humble and charismatic – softly spoken, but her words
        struck deep into your consciousness. She was dedicated to the people of
        Chad, and realised this was her chance to help them from afar.
        Fundraising took many forms throughout England, and indeed Ireland, and
        many thousands of pounds were raised and sent on to Chad.
      </p>
      <h2 className="mb-4 text-lg font-bold">
        How The Funds We Raised Were Spent
      </h2>
      <ul className="pl-4">
        <li className="list-disc mb-4">
          Since those early days, the money we have sent has made an incredible
          difference to the lives of those around Bénoye and M’beri.{" "}
        </li>
        <li className="list-disc mb-4">
          The Sisters who continued Dominique’s work in Chad have built wells
          and shown them how to maintain them, educating people about hygiene
          and the importance of clean water.
        </li>
        <li className="list-disc mb-4">
          We have built granaries so that crops could be stored.
        </li>
        <li className="list-disc mb-4">
          Over the years, we’ve contributed to the purchase of several 4-wheel
          drive vehicles, which are essential to transport the sick and elderly,
          and to take supplies to outlying villages.
        </li>
        <li className="list-disc mb-4">
          We have built a school, where around 200 children are taught and fed
          daily.
        </li>
        <li className="list-disc mb-4">
          Around a hundred younger children are being taught and fed;
          approximately thirty older girls are being educated; approximately ten
          thousand patients last year took advantage of medical consultation by
          visiting our dispensary.
        </li>

        <li className="list-disc mb-4">
          Gradually, small dispensaries were set up, using basic medical
          supplies, and more recently, a larger dispensary was built.{" "}
        </li>
        <li className="list-disc mb-4">
          We have sent several large consignments of urgently needed medicines
          which helps over 70 patients a day.{" "}
        </li>
        <li className="list-disc mb-4">
          We have also provided a power generator, extending the hours of
          medical attention.
        </li>
      </ul>
      <h2 className="mb-4 text-lg font-bold">Our Introduction To Peru</h2>
      <p className="mb-4">
        In 1989, we were approached by Sisters and told of the abysmal
        conditions in Peru. They worked in two missions:
      </p>
      <ul className="list-disc p-4">
        <li className="pb-4">
          In San Juan del Oro, in the Andes province of Sandia, where the people
          are extremely poor and many suffered from tuberculosis (TB).
        </li>
        <li>
          In slums in Lima, where life was miserable. The children did not go to
          school, and were so hungry they ate newspapers and soil, and made soup
          from ground-up cardboard.
        </li>
      </ul>
      <p className="mb-8">
        With our charity already established, we decided to support the two
        missions in Peru, and Aid For Chad evolved into Aid For Chad & Peru. We
        soon heard how our money was being used to set up soup kitchens, pay for
        medicine, support families in dire need and help prisoners with severely
        restricted human rights.Traditionally, children could only attend school
        if they wore uniform, so we paid for uniforms and books. In San Juan del
        Oro, we have built a dining room where to this day 200 children are fed
        daily, supplied with books and school equipment, and helped with
        medicine to combat TB.
      </p>
      <p className="mb-8">
        Sadly, as pointed out above, the San Juan mission had to close.
      </p>
      <h2 className="mb-4 text-lg font-bold">Merchandise</h2>
      <p className="mb-4">
        In 1998, we were introduced to two Australian nuns who ran women’s
        groups in the shanty towns of Lima, teaching the local women skills such
        as crocheting, knitting, embroidery, hairdressing and dressmaking. They
        now produce the most exquisite handicrafts including stunning dolls,
        wall hangings, bags and Christmas decorations.
      </p>
      <p className="mb-4">
        By then, the charity had moved into a new phase – electronically
        ordering the handicrafts, and selling them wherever we could. Little has
        changed since. As soon as we receive the goods, we send money straight
        back to the Sisters, who pay the ladies so that they are able to support
        their families. Not only are we feeding them through our charity, their
        dignity is restored as they have directly improved their lives due to
        their hard work. However, we are constantly striving to think of new
        ideas to suggest to the ladies and find new outlets for them.
      </p>
      <p className="mb-8">
        Sister Dominique was thrilled that we were able to help desperate
        families in Peru as well as her beloved Chad. She agreed with us that
        introduction of the beautiful handicrafts breathed new life into Aid For
        Chad & Peru – they provided a direct passage between the First and Third
        Worlds. We continue to send funds to Bénoye, M'beri and Lima three times
        a year, as well as supporting the Women's Group.
      </p>
      <h2 className="mb-4 text-lg font-bold">Sister Dominique’s Last Years</h2>
      <p className="mb-8">
        Sister Dominique spent the last phase of her life teaching at St. Bede’s
        Primary School in Basingstoke, near the Alton Convent. In March 2005,
        she suffered a stroke, and sadly passed away. She knew that the seed she
        sowed with her first-hand accounts from Chad continues to flourish.
      </p>
      <h2 className="mb-4 text-lg font-bold">How We Are Heading Forward</h2>
      <p className="mb-4">
        Through many different types of contribution Aid for Chad continues to
        raise funds to support the needy in Chad and Peru
      </p>
      <p className="mb-4">
        Please <Link to="/contact/">get in touch</Link> if you would like to
        help us.
      </p>
    </PageLayout>
  )
}
